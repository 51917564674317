<template>
  <div>
    <div class="w-100 row mx-0 px-0 navy-blue-disabled-border-bottom-1px" v-if="!readOnly">
      <div class="px-1">
        <custom-multi-select
            :item-per-row="1"
            :max="1"
            :model.sync="currencyUnitIdProxy"
            :not-list="true"
            :options="currencyOptions"
            :required="false"
            @onSelect="changedCurrencyUnitIdProxySelect=true"
            title=""
            name="invoice_currency"
        ></custom-multi-select>
      </div>
      <div class="px-1">
        <custom-multi-select
            :item-per-row="1"
            :max="1"
            :model.sync="buyingTypeIdProxy"
            :not-list="true"
            :options="buyingTypeOptions"
            :required="false"
            title=""
            name="invoice_buying_type"
        ></custom-multi-select>
      </div>
      <div class="px-1">
        <number-input
            :title="null"
            name="invoice_exchange"
            :model.sync="exchangeProxy"
            :step="0.01"
            :placeholder="$t('invoice.please_enter_exchange')"
            :is-inline="true"
            :required="false"
            :disabled="disabled"
            input-max-width="100px"
        ></number-input>
      </div>
    </div>
    <div class="w-100 row mx-0 px-0 font-weight-bold">
      <div class="col-12 py-1 pr-2 d-flex justify-content-between navy-blue-disabled-border-bottom-1px">
        <div class="detail-width">{{ $t('invoice.material_service_total_amount')}}</div>
        <div class="w-100px">{{ total | priceFormat }} {{ selectedCurrency }}</div>
      </div>

      <div class="col-12 py-1 pr-2 d-flex justify-content-between navy-blue-disabled-border-bottom-1px">
        <div class="detail-width">{{ $t('invoice.total_discount')}}</div>
        <div class="w-100px">
          <number-input
              :title="null"
              name="invoice_discount"
              :model.sync="discountProxy"
              :step="0.01"
              :placeholder="$t('invoice.please_enter')"
              :is-inline="true"
              :required="false"
              :disabled="disabled"
              input-max-width="100px"
          ></number-input>
        </div>
      </div>

      <div class="col-12 py-1 pr-2 d-flex justify-content-between navy-blue-disabled-border-bottom-1px" v-for="vat_rate in vatRates">
        <div class="detail-width">{{ $t('invoice.calculate_vat')}} (% {{ vat_rate.rate}} )</div>
        <div class="w-100px">{{ vat_rate.sum  | priceFormat }}  {{ selectedCurrency }}</div>
      </div>

      <div class="col-12 py-1 pr-2 d-flex justify-content-between navy-blue-disabled-border-bottom-1px" v-if="+item.is_withholding === 1">
        <div class="detail-width">
          {{ $t('invoice.withholding') }}
          <span class="font-weight-normal">({{ item.vat_deduct_description }} (% {{ withholdingRate }}))</span>
        </div>
        <div class="w-100px">
          <div class="w-100px">{{ withholdingAmount | priceFormat }} {{ selectedCurrency }}</div>
        </div>
      </div>
      <div class="col-12 py-1 pr-2 d-flex justify-content-between navy-blue-disabled-border-bottom-1px" v-if="+item.is_withholding === 1">
        <div class="detail-width">{{ $t('invoice.calculated_transaction_withholding')}}</div>
        <div class="w-100px">{{  calculated_vat_withholding  | priceFormat }} {{ selectedCurrency }}</div>
      </div>
      <div class="col-12 py-1 pr-2 d-flex justify-content-between navy-blue-disabled-border-bottom-1px">
        <div class="detail-width">{{ $t('invoice.total_with_tax')}}</div>
        <div class="w-100px">{{ total_amount | priceFormat }} {{ selectedCurrency }}</div>
      </div>

      <div class="col-12 py-1 pr-2 d-flex justify-content-between navy-blue-disabled-border-bottom-1px">
        <div class="detail-width">{{ $t('invoice.amount_to_be_paid')}}</div>
        <div class="w-100px">{{ total_amount_including_taxes | priceFormat }} {{ selectedCurrency }}</div>
      </div>

      <div class="col-12 py-1 pr-2 d-flex justify-content-between" v-if="+item.currency_unit_id !== 160 && +item.currency_unit_id !==53">
          <div class="detail-width">{{ $t('invoice.exchange_rate') }}</div>
          <div class="w-100px">{{ exchange | exchangeRateFormat }}</div>
      </div>

      <template v-if="+item.currency_unit_id !== 160">
        <div class="col-12 py-1 pr-2 d-flex justify-content-between navy-blue-disabled-border-bottom-1px mt-2" :class="`${+item.currency_unit_id !== 160 && +item.currency_unit_id !==53 ? 'navy-blue-disabled-border-top-1px' : ''}`">
          <div class="detail-width">{{ $t('invoice.material_service_total_amount') }} ({{ defaultCurrency }})</div>
          <div class="w-100px">{{ total_converted | priceFormat }} {{ defaultCurrency }}</div>
        </div>
        <div class="col-12 py-1 pr-2 d-flex justify-content-between navy-blue-disabled-border-bottom-1px">
          <div class="detail-width">{{ $t('invoice.total_discount') }} ({{ defaultCurrency }})</div>
          <div class="w-100px">
            {{ discount_converted | priceFormat }} {{ defaultCurrency }}
          </div>
        </div>
        <div class="col-12 py-1 pr-2 d-flex justify-content-between navy-blue-disabled-border-bottom-1px" v-for="vat_rate in vatRates">
          <div class="detail-width">{{ $t('invoice.calculate_vat')}} (% {{ vat_rate.rate}} )</div>
          <div class="w-100px">{{ vat_rate.sum * exchange | priceFormat }}  {{ defaultCurrency }}</div>
        </div>
        <div class="col-12 py-1 pr-2 d-flex justify-content-between navy-blue-disabled-border-bottom-1px" v-if="+item.is_withholding === 1 | +item. withholding_amount>0">
          <div class="detail-width">
            {{ $t('invoice.withholding') }}
            <span class="font-weight-normal">({{ item.vat_deduct_description }} (% {{ withholdingRate }}))</span>
          </div>
          <div class="w-100px">
            <div class="w-100px">{{ withholdingAmountConverted | priceFormat }} {{ defaultCurrency }}</div>
          </div>
        </div>

        <div class="col-12 py-1 pr-2 d-flex justify-content-between navy-blue-disabled-border-bottom-1px">
          <div class="detail-width">{{ $t('invoice.total_with_tax') }} ({{ defaultCurrency }})</div>
          <div class="w-100px">{{ total_amount_converted  | priceFormat }} {{ defaultCurrency }}</div>
        </div>
        <div class="col-12 py-1 pr-2 d-flex justify-content-between">
          <div class="detail-width">{{ $t('invoice.amount_to_be_paid') }} ({{ defaultCurrency }})</div>
          <div class="w-100px">{{total_amount_including_taxes_converted  | invoiceFormat }} {{ defaultCurrency }}</div>
        </div>

        
      </template>
    </div>
  </div>
</template>

<script>
import SelectInput from "@/assets/components/inputs/SelectInput";
import NumberInput from "@/assets/components/inputs/NumberInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";

export default {
  name: "InvoiceLayout",
  props: {
    item: {
      required: true,
      default: null,
    },
    disabled: {
      required: false,
      default: false,
    },
    exchange: {
      required:true,
      default: 1,
    },
    buying_type_id: {
      required: true,
      default:null,
    },
    currency_unit_id: {
      required:true,
    },
    buyingTypeOptions: {
      required: true,
    },
    currencyOptions: {
      required: true,
    },
    vatOptions: {
      required: true,
    },
    transactions: {
      required: true,
    },
    discount: {
      required: true,
    },
    currencyRates: {
      required: true
    },
    vatRates: {
      required: true
    },
    readOnly: {
      required: false,
      default: false,
    }
  },
  data() {
    return {
      defaultCurrency: 'TL',
      changedCurrencyUnitIdProxySelect:false,
    }
  },
  components: {NumberInput, SelectInput, CustomMultiSelect},
  computed: {
    withholdingRate() {
      if (this.item.withholding_denominator && this.item.withholding_share) {
        return this.item.withholding_share / this.item.withholding_denominator * 100;
      }
      return 0;
    },
    withholdingAmount() {
      if (this.calculatedVat && this.item.withholding_share && this.item.withholding_denominator) {
        return this.calculatedVat * this.item.withholding_share / this.item.withholding_denominator;
      }
      return 0;
    },
    withholdingAmountConverted() {
      return this.withholdingAmount * this.exchange;
    },
    selectedCurrency() {
      if (this.currency_unit_id) {
        return this.currencyOptions[this.currency_unit_id]
      }
      return "";
    },
    calculated_vat_withholding() {
      let sum = 0;
      let self = this;
      this.transactions.forEach(item => {
        let amount = Number(item.amount);
        if(item.divided_products){
          amount += Number(item.divided_products.transaction_amount);
        }
        sum += amount * item.unit_price * 4 / 100;
      })
      return sum.toFixed(2);
    },
    calculatedVat() {
      let sum = 0;
      let self = this;
      this.transactions.forEach(item => {
        if(item.vat_price>0){
          let amount = Number(item.amount);
          if(item.divided_products){
            amount += Number(item.divided_products.transaction_amount);
          }

          sum += amount * item.unit_price * self.vatOptions[item.vat_rate_id] / 100;
        }

      })
      return sum.toFixed(2);
    },
    total_amount_including_taxes() {
      return Number(this.total) + Number(this.calculatedVat) - Number(this.discount) - Number(this.withholdingAmount);
    },
    total_amount_including_taxes_converted() {
      return Number(this.total_amount_including_taxes) * this.exchange;
    },
    total_amount() {
      return Number(this.total) + Number(this.calculatedVat);
    },
    total_amount_converted() {
      return Number(this.total_amount) * this.exchange
    },
    total() {
      let sum = 0;
      this.transactions.forEach(item => {
        let amount = Number(item.amount);
          if(item.divided_products){
            amount += Number(item.divided_products.transaction_amount);
          }
        sum += amount * item.unit_price;
      })
      return sum;
    },
    total_converted() {
      return this.total * this.exchange;
    },
    discount_converted () {
      return this.discount * this.exchange;
    },
    exchangeProxy: {
      set(value){
        this.exchange = null;
        this.$emit('update:exchange', value);
      },
      get(){
        return this.exchange || this.exchange;
      }
    },
    discountProxy: {
      set(value){
        this.$emit('update:discount', value);
      },
      get(){
        return this.discount;
      }
    },
    currencyUnitIdProxy: {
      set(value) {
        this.$emit('update:currency_unit_id', value);
      },
      get(){
        return this.currency_unit_id;
      }
    },
    buyingTypeIdProxy: {
      set(value) {
        this.$emit('update:buying_type_id', value);
      },
      get(){
        return this.buying_type_id;
      }
    }
  },
  watch: {
    currencyUnitIdProxy(value) {
      if (value && this.changedCurrencyUnitIdProxySelect===true) {
        let tmpItem = this.currencyRates[this.currencyOptions[value]];
        this.exchange = tmpItem ? tmpItem.rate : 1;
        this.$emit('update:exchange', this.exchange);
      }
      this.changedCurrencyUnitIdProxySelect=false;
    }
  }
}
</script>

<style scoped>
.detail-width {
  max-width: calc(100% - 100px) !important;
}
</style>
